/* Roboto Condensed - Light */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Roboto Condensed - Light Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Roboto Condensed - Regular */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Roboto Condensed - Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Roboto Condensed - Medium */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Roboto Condensed - Medium Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Roboto Condensed - SemiBold */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Roboto Condensed - SemiBold Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Roboto Condensed - Bold */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Roboto Condensed - Bold Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Roboto Condensed - ExtraBold */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Roboto Condensed - ExtraBold Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* Roboto Condensed - Black */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Roboto Condensed - Black Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Roboto Condensed - Thin */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Roboto Condensed - Thin Italic */
@font-face {
  font-family: "Roboto Condensed";
  src: url("./RobotoCondensed-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
