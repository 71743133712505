@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("../src//assets/fonts/font.css");

@layer base {
  body {
    font-family: "Roboto Condensed", sans-serif;
    background-color: #12182b;
  }
  .ag-root,
  .ag-header-cell,
  .ag-header-cell-text,
  ag-header-cell-label,
  .ag-cell {
    font-family: "Roboto Condensed", sans-serif;
  }
}
.swiper-container {
  overflow: visible;
  height: 250px;
}
.swiper {
  overflow: visible;
}
.swiper-wrapper {
  overflow: visible;
  margin: 20px 0;
  /* min-height: 200px; */
}
.custom-phone-input {
  display: flex;
  align-items: center;
}

.react-tel-input .country-list {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 9999 !important;
}

.flag-selector .react-tel-input .form-control {
  margin-left: 59px;
}
@keyframes autofill {
  to {
    /* Empty animation to trigger the event */
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.loader {
  animation: spin 1s linear infinite;
  border: 4px solid #666cff;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 58px;
  height: 58px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  0% {
    transform: translateX(-100%) translateY(-100%);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: translateX(100%) translateY(100%);
    opacity: 0;
  }
}

.glow-line {
  position: absolute;
  top: -15%;
  left: -15%;
  right: -15%;
  bottom: -15%;
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 60%
  );
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

.group:hover .glow-line {
  animation: glow 2s ease forwards;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main-no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
